import UserStore from 'stores/user_store'
import AsyncRequestStore from 'stores/asyncRequestStore'
import Api from 'web-client/utils/api'

import userService from 'shared/utils/user'
import oauthService from 'web-client/utils/oauth'

const legacyLogOutOfPhone = () =>
  new Promise((resolve) => {
    const isDispatcher = UserStore.isSwoopDispatcher()
    const extension = UserStore.getExtension()

    if (isDispatcher && !!extension) {
      const user = UserStore.getUser()

      AsyncRequestStore.logoutOfPhone(extension, user, {
        error: () => {
          resolve()
        },
        success: () => {
          resolve()
        },
      })
    } else {
      resolve()
    }
  })

const legacyRevokeToken = () =>
  new Promise((resolve) => {
    Api.revokeToken(() => {
      Api.clearBearerToken()
      resolve()
    })
  })

// see bridge.js for notes

userService.injectLegacy('cleanup', UserStore.cleanup)
userService.injectLegacy('logoutOfPhone', legacyLogOutOfPhone)
oauthService.injectLegacy('revokeToken', legacyRevokeToken)
