import { find } from 'lodash'
import datadog from 'shared/utils/logging/integrations/datadog'
import createUUID from 'web-client/utils/createUUID'
import logging from 'shared/utils/logging'
import { getLocalizeTranslate } from './window'

// UNDOCUMENTED, we have found that Localize JS reserves the following terms,
// which should not be used as variable names;
// have them here so that we can do safety things.
const localizeJsReservedTerms = [
  'accept',
  'accept-charset',
  'accesskey',
  'action',
  'align',
  'alt',
  'async',
  'autocomplete',
  'autofocus',
  'bgcolor',
  'border',
  'checked',
  'cite',
  'class',
  'color',
  'cols',
  'colspan',
  'disabled',
  'download',
  'href',
  'hreflang',
  'multiple',
  'placeholder',
  'readonly',
  'pluralize',
  'draggable',
  'dropzone',
  'hidden',
  'id',
  'spellcheck',
  'required',
  'reversed',
  'rows',
  'selected',
  'src',
  'style',
  'tabindex',
  'ignore',
  'language',
]

const varsSafetyCheck = (template: string, vars: any): [string, Record<string, string>] => {
  const regex = /%\{(.*?)\}/g
  const safeVars: Record<string, string> = {}
  let safeTemplate = template
  // trim spaces around template vars
  let match = regex.exec(safeTemplate)
  while (match !== null) {
    safeTemplate = safeTemplate.replace(match[0], `%{${match[1].trim()}}`)
    match = regex.exec(safeTemplate)
  }

  // compare template vars against list of reserved terms
  match = regex.exec(safeTemplate)
  while (match !== null) {
    // eslint-disable-next-line no-loop-func
    if (find(localizeJsReservedTerms, (term) => match && term === match[1])) {
      safeTemplate = safeTemplate.replace(match[0], `%{safe_${match[1]}}`)
      safeVars[`safe_${match[1]}`] = vars[match[1]]
    } else {
      safeVars[match[1]] = vars[match[1]]
    }
    match = regex.exec(safeTemplate)
  }

  return [safeTemplate, safeVars]
}

// inserts _vars_ into the _template_ string
const interpolate = (template: string, vars: any) => {
  const regex = /%\{(.*?)\}/g
  let interpolation = template
  let match = regex.exec(template)
  while (match !== null) {
    const replaceText: string = vars[match[1]]
    interpolation = interpolation.replace(match[0], replaceText)
    match = regex.exec(template)
  }
  return interpolation
}

// get translated string from Localize JS, else return interpolated arguments
const localize = (template = '', vars: any = {}) => {
  if (!template) {
    datadog.logError('Invalid template for localize', {
      template,
      vars,
    })
    return ''
  }
  const [safeTemplate, safeVars] = varsSafetyCheck(template, vars)
  const translate = getLocalizeTranslate()
  if (translate) {
    /**
     * Because Localize JS executes XSS attacks,
     * we need to jump through some hoops to protect ourselves ...
     */
    const varsWithFakeValues: Record<string, string> = {} // for each var, replace the value with a uuid
    const varsMap: Record<string, string> = {} // for each var, map the value using the uuid as key
    Object.entries(safeVars).forEach(([key, value]) => {
      const uuid = createUUID() // generate the uuid
      varsWithFakeValues[key] = uuid // replace the value
      varsMap[uuid] = value // map the value
    })
    // send the fakes, get the translated string template
    let localizedString = translate(safeTemplate, varsWithFakeValues)

    if (localizedString) {
      // replace each uuid with its corresponding value
      Object.keys(varsMap).forEach((key) => {
        const replaceText: string = varsMap[key]
        localizedString = localizedString.replace(key, replaceText)
      })

      return localizedString
    } else {
      // Fallback
      logging.logError(Error('localizedString is undefined'), {
        template,
        vars,
        safeTemplate,
        varsWithFakeValues,
      })
    }
  }

  return interpolate(safeTemplate, safeVars)
}

export { interpolate, localize }
