// @ts-ignore
import MicroEvent from 'microevent-github'
import Consts from 'consts'
import Dispatcher from 'shared/utils/dispatcher'
import { sortBy, values } from 'lodash'

const triggers = {
  NEW_NOTIFICATION: 'new_notification',
  CLOSE_NOTIFICATION: 'close_notification',
  REFRESH_NOTIFICATION: 'refresh_notification',
  CHANGE: 'change',
  REFRESH: 'refresh',
}

Object.entries(triggers).forEach(([key, val]) => {
  ;(window as any)[key] = val
})

type Notification = {
  id?: number
  message?: string
  type?: string
  timeout?: number
  perpetual?: boolean
}

type NotificationStoreType = {
  getNotifications: () => { [key: string]: Notification }
  getMostRecentNotification: () => Notification | undefined
  removeNotification: (checkText: string) => void
  [key: string]: any
}

const NotificationStore: NotificationStoreType = (() => {
  const notifications: { [key: string]: Notification } = {}
  let id = 1
  let trigger: (t: string) => void = () => {}

  const store: NotificationStoreType = {
    getNotifications: () => notifications,

    getMostRecentNotification: () => {
      const sorted = sortBy(values(notifications), 'id')
      return sorted[sorted.length - 1]
    },

    removeNotification: (checkText: string) => {
      Object.keys(notifications).forEach((k) => {
        const v = notifications[k]
        if (v?.message && typeof v.message === 'string' && v.message.indexOf(checkText) > -1) {
          delete notifications[k]
        }
      })
      trigger(triggers.CHANGE)
    },
  }

  Object.keys(triggers).forEach((key) => {
    store[key] = triggers[key as keyof typeof triggers]
  })

  MicroEvent.mixin(store)
  trigger = store.trigger.bind(store)

  store[triggers.NEW_NOTIFICATION] = (notification: Notification) => {
    if (!notification.id) {
      id += 1
      // eslint-disable-next-line no-param-reassign
      notification.id = id
    } else if (notifications[notification.id]) {
      // Already displaying this notification
      return
    }

    if (
      !notification.perpetual &&
      notification.type !== Consts.ERROR &&
      notification.timeout &&
      notification.timeout > 0
    ) {
      setTimeout(() => {
        store[triggers.CLOSE_NOTIFICATION](notification)
      }, notification.timeout)
    }

    notifications[notification.id] = notification
    trigger(triggers.CHANGE)
  }

  store[triggers.CLOSE_NOTIFICATION] = (notification: Notification) => {
    if (notification.id && notifications[notification.id]) {
      delete notifications[notification.id]
      trigger(triggers.CHANGE)
    }
  }

  store[triggers.REFRESH_NOTIFICATION] = () => {
    trigger(triggers.REFRESH)
  }

  Dispatcher.register((payload: any) => {
    if (!payload.eventName) {
      console.warn('empty Event in payload: ', payload)
    } else if (typeof store[payload.eventName] === 'function') {
      store[payload.eventName](payload.data)
    }
    return true
  })

  return store
})()

export default NotificationStore
