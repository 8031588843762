import sessionService from 'web-client/utils/session'
import Api from 'web-client/utils/api'

/**
 * The new logout function is an _interface_,
 * the implementation of which we don't about.
 *
 * Because of time constraints we cannot fully port
 * the legacy "brownfield" logout functionality from UserStore
 * into greenfield. This is a temporary solution that accomplishes 2 goals:
 *
 * 1. Keeps the new code from directly importing a legacy dependency (UserStore).
 * 2. Allows us to hook into (or "bridge" to) the legacy code from greenfield.
 *
 * We don't want to directly import legacy code like the UserStore
 * directly in the new code, we are trying as best as possible to get rid of it.
 * However, there will be times when we need to call into the brownfield/legacy
 * code from the greenfield/new code, as this cannot be avoided. This pattern
 * establishes a way to do it without polluting the new code.
 */

sessionService.inject('setBearerToken', (token) => Api.setBearerToken(token))
sessionService.inject('setExpiresAt', (int) => Api.setExpiresAt(int))
