/* eslint-disable relay/unused-fields */
// eslint-disable-next-line no-restricted-imports
import { graphql } from 'relay-hooks'
import { commitMutation } from 'react-relay'
import { fetchQuery } from 'relay-runtime'

import Consts from 'consts'
import sessionService from 'web-client/utils/session'
import auth0Service from 'web-client/utils/auth0'
import oauthService from 'web-client/utils/oauth'
import logging from 'shared/utils/logging'
import datadog from 'shared/utils/logging/integrations/datadog'

import type { Environment } from 'react-relay'
import type { Credentials as LegacyCredentials } from 'web-client/utils/authenticate'
import type { userMFAQuery, userMFAQuery$data } from './__generated__/userMFAQuery.graphql'
import type { userMFAMutation } from './__generated__/userMFAMutation.graphql'
import type { userCheckMFAStatusQuery } from './__generated__/userCheckMFAStatusQuery.graphql'

export * from './role'

type UserService = {
  legacy: {
    logoutOfPhone: () => Promise<void>
    cleanup: () => void
  }
  injectLegacy: (
    methodName: keyof UserService['legacy'],
    method: (() => Promise<void>) & (() => void)
  ) => void
  logoutWithError: (message: string, e?: any) => Promise<void>
  clearMaintainance: () => void
  login: (relayEnvironment: Environment) => Promise<boolean | undefined>
  maintainSession: () => void
  authenticateWithCredentials: (
    credentials: LegacyCredentials & {
      mfa_remember_token: string | null
      mfa_strategy?: string
    }
  ) => Promise<boolean>
  redirectToSSOProvider: (name: string, companyName: string) => void
  isAuthenticated: () => boolean
  isFallbackAuthenticated: () => boolean
  refreshAuth0TokenIn: (expiresIn: number, triedOnceAlready?: boolean) => void
  logoutOfPhone: () => Promise<void>
  logout: () => Promise<void>
  fetchMFAStatus: (
    env: Environment,
    login: string,
    password: string
  ) => Promise<userMFAQuery$data['mfaStrategy'] | undefined>
  sendMFACode: (
    env: Environment,
    login: string,
    password: string,
    strategy: string
  ) => Promise<string | void>
  getMFAErrorCode: (
    env: Environment,
    credentials: LegacyCredentials & {
      mfa_strategy: string
      mfa_token?: string
    }
  ) => Promise<string | null | undefined>
}

let logoutPromise: Promise<void> | null, auth0RefreshTimer: number

const checkMFAStatusQuery = graphql`
  query userCheckMFAStatusQuery(
    $login: String!
    $password: String!
    $mfaStrategy: String!
    $mfaToken: String
  ) {
    mfaStatus(login: $login, password: $password, mfaStrategy: $mfaStrategy, mfaToken: $mfaToken) {
      code
    }
  }
`

const mfaQuery = graphql`
  query userMFAQuery($login: String!, $password: String!, $rememberToken: String) {
    mfaStrategy(login: $login, password: $password, rememberToken: $rememberToken) {
      required
      strategy
      remembered
      lockedOut
      validCredentials
    }
  }
`

const mfaMutation = graphql`
  mutation userMFAMutation($input: RequestMfaTokenInput!) {
    requestMfaToken(input: $input) {
      mfaResponse
      mfaRememberToken
    }
  }
`

const userService: UserService = {
  legacy: {
    logoutOfPhone(): Promise<void> {
      throw new Error('Not implemented')
    },

    cleanup() {
      throw new Error('Not implemented')
    },
  },

  injectLegacy(methodName, method) {
    userService.legacy[methodName] = method
  },

  async login(relayEnvironment: Environment) {
    if (auth0Service.looksLikeResponse()) {
      try {
        await auth0Service.login(relayEnvironment)
      } catch (e) {
        if (!(e instanceof Error)) {
          throw e
        }

        if (e.message.match(/User not found/i)) {
          void userService.logoutWithError(
            'This account does not exist in Swoop. Please contact your administrator to create your Swoop account.',
            e
          )
        } else {
          void userService.logoutWithError(Consts.TRY_AGAIN_LATER_RESPONSE, e)
        }
        return
      }
      userService.maintainSession()
      return true
    } else {
      return false
    }
  },

  async authenticateWithCredentials(credentials) {
    await oauthService.authenticate(credentials)
    return true
  },

  redirectToSSOProvider(name, companyName) {
    const id = sessionService.createId()

    // sets global datadog properties to be logged.
    datadog.mergeProps({
      sso_connection_name: name,
      company_name: companyName,
      session_id: id,
    })

    // actually calls the log with some extra props.
    logging.logInfo('Start authentication redirect', {
      feature: 'SSO',
    })

    auth0Service.redirect(name)
  },

  isAuthenticated() {
    return sessionService.hasBearerToken()
  },

  isFallbackAuthenticated() {
    return sessionService.hasBearerToken() && sessionService.hasFallbackToken()
  },

  refreshAuth0TokenIn(expiresIn, triedOnceAlready?) {
    const handleRefresh = () => {
      auth0Service
        .refresh()
        .then(() => {
          userService.refreshAuth0TokenIn(5000)
        })
        .catch((error) => {
          if (triedOnceAlready) {
            void userService.logoutWithError(
              'Please re-enter your login information to keep using Swoop.',
              error
            )
          } else {
            logging.logWarning('Failure refreshing token; trying one more time.', { error })
            userService.refreshAuth0TokenIn(5000, true)
          }
        })
    }
    userService.clearMaintainance()
    auth0RefreshTimer = window.setTimeout(handleRefresh, expiresIn)
  },

  maintainSession() {
    if (auth0Service.isAuthenticated()) {
      userService.refreshAuth0TokenIn(5000)
    }
  },

  clearMaintainance() {
    clearTimeout(auth0RefreshTimer)
  },

  logoutOfPhone() {
    return userService.legacy.logoutOfPhone()
  },

  logout() {
    if (logoutPromise != null) {
      return logoutPromise
    }

    if (sessionService.isLoggingOut) {
      return Promise.resolve()
    }

    sessionService.isLoggingOut = true

    logoutPromise = new Promise((resolve) => {
      const canLogout = userService.isAuthenticated()

      const handleSuccess = (skipReload?: boolean) => {
        // do legacy cleanup
        userService.legacy.cleanup()

        // clear localStorage
        sessionService.clear()

        setTimeout(() => {
          logoutPromise = null
          sessionService.isLoggingOut = false
        }, 0)
        resolve()

        // Skip only in the case of SSO redirection
        if (!skipReload) {
          // reload window so we
          // clear everything in memory
          location.reload()
        }
      }

      if (canLogout) {
        userService.clearMaintainance()

        void userService.logoutOfPhone().then(() => {
          if (auth0Service.isAuthenticated()) {
            // clear refresh mechanism
            auth0Service.clear()
            if (auth0Service.client) {
              handleSuccess(true)
              auth0Service.initialize()
              // auth0 logout will redirect page to auth0's api
              void auth0Service.client.logout({ returnTo: `${window.ENV.SITE_URL}` })
            } else {
              handleSuccess()
            }
          } else {
            // revoke token on backend.
            void oauthService.revokeToken().then(() => handleSuccess())
          }
        })
      } else {
        resolve()
        setTimeout(() => {
          logoutPromise = null
          sessionService.isLoggingOut = false
        }, 0)
      }
    })

    return logoutPromise
  },

  logoutWithError(message, error?) {
    sessionService.setLoginFlashMessage(message)
    logging.logWarning('Logout with error message', { error })
    return userService.logout()
  },

  async fetchMFAStatus(env, login, password) {
    const rememberToken = sessionService.getMfaRememberToken()
    const variables = {
      login,
      password,
      rememberToken,
    }
    const data = await fetchQuery<userMFAQuery>(env, mfaQuery, variables).toPromise()
    return data?.mfaStrategy
  },

  sendMFACode(env, login, password, strategy) {
    const variables = {
      input: {
        mfaRequest: {
          login,
          password,
          strategy,
        },
      },
    }

    return new Promise<string | void>((resolve, reject) => {
      commitMutation<userMFAMutation>(env, {
        mutation: mfaMutation,
        variables,
        onCompleted: (response) => {
          if (response.requestMfaToken) {
            sessionService.setMfaRememberToken(response.requestMfaToken.mfaRememberToken)
            resolve(response.requestMfaToken.mfaRememberToken)
          } else {
            sessionService.removeMfaRememberToken()
            resolve()
          }
        },
        onError: reject,
      })
    })
  },

  async getMFAErrorCode(env, credentials) {
    const variables = {
      login: 'email' in credentials ? credentials.email : credentials.username,
      password: credentials.password,
      mfaStrategy: credentials.mfa_strategy,
      mfaToken: credentials.mfa_token,
    }
    const data = await fetchQuery<userCheckMFAStatusQuery>(
      env,
      checkMFAStatusQuery,
      variables
    ).toPromise()
    return data?.mfaStatus?.code
  },
}

export default userService
