import { useEffect } from 'react'
import { useRouter } from 'react-router5'

import isAuthenthicated from 'web-client/utils/isAuthenticated'
import userService from 'shared/utils/user'
import logging from 'shared/utils/logging'
import useCurrentUser from 'web-client/hooks/useCurrentUser'

function RedirectToHome() {
  const { currentUser, isLoading } = useCurrentUser()
  const router = useRouter()

  useEffect(() => {
    if (!isAuthenthicated()) {
      router.navigate('login', {}, { replace: true })
    }
  }, [router])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (currentUser.permissions.isClient) {
      router.navigate('client', {}, { replace: true })
    } else if (currentUser.permissions.isPartner || currentUser.permissions.isSuperCompany) {
      router.navigate('partner/dispatch', {}, { replace: true })
    } else {
      logging.logWarning('Unable to determine home route for user', {})
      void userService.logout()
    }
  }, [currentUser, isLoading, router])

  return null
}

export default RedirectToHome
