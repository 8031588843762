import { useEffect, useState } from 'react'

import Consts from 'consts'
import useSplit from 'shared/hooks/useSplit'
import datadog from 'shared/utils/logging/integrations/datadog'
import { useStoreBind } from 'stores/storeHooks'
import UserStore from 'stores/user_store.coffee'
import useCurrentUser from './useCurrentUser'

export interface WalkMeWindow extends Window {
  _walkmeConfig: { smartLoad: boolean }
  _walkMe: any
  _walkme: {
    user: {
      id?: string
      name?: string | null
      type?: string
    }
  }
}

declare let window: WalkMeWindow

const SCRIPT_ID = 'walk_me_swoop_script'

const useWalkMe = () => {
  useStoreBind(UserStore, UserStore.USERME_CHANGED)
  const [isLoaded, setIsLoaded] = useState(false)
  const isWalkMeEnabled = useSplit(Consts.SPLITS.ENABLE_WALKME_INTEGRATION_TOOL)
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (currentUser) {
      window._walkme = {
        user: {
          id: currentUser.id,
          name: currentUser.name,
          type: currentUser.company?.type,
        },
      }
    }
  }, [currentUser])

  useEffect(() => {
    let script = document.getElementById(SCRIPT_ID) as HTMLScriptElement
    const walkMeSrc = Consts.WALK_ME_FOR_SWOOP_SRC
    if (!script && !isLoaded && isWalkMeEnabled && walkMeSrc && !window._walkMe) {
      script = document.createElement('script')
      script.id = SCRIPT_ID
      script.async = true
      script.onload = () => setIsLoaded(true)
      script.onerror = () => datadog.logError('Failed loading Walk Me script')
      script.src = walkMeSrc
      // eslint-disable-next-line prefer-destructuring
      const firstScript = document.getElementsByTagName('script')[0]
      firstScript.parentNode?.insertBefore(script, firstScript)
      window._walkmeConfig = { smartLoad: true }
    }
  }, [isLoaded, isWalkMeEnabled])
}

export default useWalkMe
