import { createConsumer } from '@rails/actioncable'
import sessionService from 'web-client/utils/session'
import logging from 'shared/utils/logging'
import Consts from 'consts'
import datadog from 'shared/utils/logging/integrations/datadog'
import { getUUID } from 'consumer-mobile-web/utils/jobutils'

const SAMPLE_RATE = 10000;
let sampleRateIndex = Math.floor(Math.random()*SAMPLE_RATE)

let cable = null

export function closeActionCable() {
  if(cable){
    //TODO: do we need to unsubscribe from everything here when disconnecting?
    cable.disconnect()
  }
}

// mostly copied from graphql-ruby's js client
/**
 * Creates a handler function for use with a subscription in Relay Client.
 * This handler function will send the subscription data over ActionCable.
 * @param {Object|null} [options]
 * @param {Object} [options.operations] - A map of operation names to operation texts.
 * @param {String} options.cable_type - The cable type.
 * @param {Boolean} [options.skipMobileAuth] - A boolean to skip mobile auth.
 *
 **/
export default function createActionCableHandler(options = {}) {
  // TODO - this needs to happen in a promise or something since this will change on logout/login?
  // NOTE: In cases where accessToken is not available, we don't include the access_token
  // query param so that AnonymousRequest can work.
  let accessToken
  let cable_type = options['cable_type']

  function getActionCableClient() {
    const accessToken = sessionService.getBearerToken()
    const mobileAuth = !options.skipMobileAuth && getUUID()
    let cableUrl =`${window.ENV?.ACTIONCABLE_URL || Consts.WEBSOCKET_URL}/cable`

    // Used for authentication on mobile web intake
    if (mobileAuth) {
      cableUrl += `?access_token=job:${mobileAuth}`
    }

    if (!mobileAuth && accessToken) {
      cableUrl += `?access_token=${accessToken}`
    }

    if (cable) {
      // re open if the url changed including the access_token
      if(cable._url !== cableUrl) {
        cable._url = cableUrl
        cable.connection.reopen()
      }
    } else {
      cable = createConsumer(cableUrl)
    }

    return cable
  }

  cable = getActionCableClient()

  return (operation, variables, cacheConfig, observer, sink, channelId) => {
    // unique-ish
    const { operations } = options
    let subscriptionDisconnectedAt = null
    let lastMessageReceivedAt = null
    // Register the subscription by subscribing to the channel
    const channel = getActionCableClient().subscriptions.create({
      channel: 'GraphQLChannel',
      channelId,
    }, {
      disconnected() {
        subscriptionDisconnectedAt = this.consumer.connection.monitor.disconnectedAt
        window.actionCableConnected = false
      },
      connected() {
        let channelParams = {
          variables,
          cable_type: cable_type,
          disconnectedAt: lastMessageReceivedAt || subscriptionDisconnectedAt,
          operationName: operation.name,
        }
        // Once connected, send the GraphQL data over the channel
        // Use the stored operation alias if possible
        if (operations) {
          channelParams['operationId'] = operations.getOperationId(operation.name)
        } else {
          channelParams['query'] = operation.text
        }
        this.perform('execute', channelParams)
        if (subscriptionDisconnectedAt) {
          logging.logInfo('Action Cable Websockets reconnected.', {
            time_disconnected: Date.now() - subscriptionDisconnectedAt,
          })
          subscriptionDisconnectedAt = null
          lastMessageReceivedAt = null
        }
        window.actionCableConnected = true
        window.DD_RUM?.addTiming('graphql_channel_connected')
      },
      // This result is sent back from ActionCable.
      received(payload) {
        // When we get a response, send the update to `observer`
        // NOTE: if any of these calls explode we silently fail, so be explicit
        // with your destructuring

        const more = payload?.more
        const result = payload?.result || {}
        const reconnect = payload?.reconnect || false
        const { errors, data } = result

        if (errors) {
          // What kind of error stuff belongs here?
          sink.error(errors)
        } else if (data) {
          this.consumer.connection.monitor.recordPing()
          lastMessageReceivedAt = this.consumer.connection.monitor.pingedAt
          sink.next({ data })
        }
        if (!more) {
          // Subscription is finished
          sink.complete()
        }
        if(data) {
          const keys = Object.keys(data)
          sampleRateIndex += 1
          if(sampleRateIndex % SAMPLE_RATE === 0){
            datadog.logInfo("Received Subscription", {
              rootNode: keys?.length > 0 ? keys[0] : null,
              sampleRate: 1/SAMPLE_RATE,
              requestName: operation.name,
              variables,
              channelId
            })
          }
        }
        if(reconnect) {
          datadog.logInfo("[ActionCable] User is being asked to reconnect")
          cable.connection.reopen()
        }
      }
    })

    return () => {
      channel.unsubscribe()
    }
  }
}
