import { last, isEmpty, every } from 'lodash'
import type { StatusType } from '@datadog/browser-logs'

type Context = {
  [x: string]: unknown
  timestamp: number | undefined
}

type Status = StatusType | Rollbar.Level

type Queue = {
  [status in Status]: {
    [name: string]: Array<Context>
  }
}

type State = {
  lastRun?: number | null
  queue: Queue
  timeout: NodeJS.Timeout | null
}

type Logger = {
  log: (name: string, data: unknown, status?: Status) => void
}

function isQueueEmpty(queue: Queue) {
  return every(queue, isEmpty)
}

function throttle(queue: Queue, logger: Logger, timeLimitMs: number) {
  const state: State = {
    lastRun: null,
    queue,
    timeout: null,
  }

  function doLog(name: string, context?: Context, status?: Status) {
    state.lastRun = Date.now()
    logger.log(name, context, status)
  }

  function processQueue() {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const status in state.queue) {
      // TODO: understand why there's an unreachable loop here and fix: ENG-35945
      // eslint-disable-next-line no-restricted-syntax,guard-for-in, no-unreachable-loop
      for (let name in state.queue[status as Status]) {
        const data = state.queue[status as Status][name]

        delete state.queue[status as Status][name]

        let context = last(data)
        if (data.length > 1) {
          context = { data, timestamp: context?.timestamp }
          name += ` (happened ${data.length} times)`
        }

        doLog(name, context, status as Status)

        if (isQueueEmpty(state.queue)) {
          state.timeout = null
        } else {
          state.timeout = setTimeout(processQueue, timeLimitMs)
        }

        break
      }
    }
  }

  function throttled(name: string, data: object, status: Status) {
    const context = {
      // add timestamp because event sending can be delayed
      timestamp: Date.now(),
      ...(data || {}),
    }

    const timeSinceLast = state.lastRun ? Date.now() - state.lastRun : null

    // if we run first time or a long time passed
    if (!state.lastRun || Number(timeSinceLast) > timeLimitMs) {
      doLog(name, context, status)
      return
    }

    // TODO log error because we are sending too much
    //  if (count > countLimit) {
    //   logger.error(`More than ${countLimit} datadog logs per ${timeLimit * countLimit} ms are sent`)
    // }

    if (!state.queue[status][name]) {
      state.queue[status][name] = []
    }

    // try to not loose different contexts
    // probably compare contexts instead of simply pushing
    state.queue[status][name].push(context)

    if (!state.timeout) {
      state.timeout = setTimeout(processQueue, timeLimitMs - Number(timeSinceLast))
    }
  }

  return throttled
}

export default throttle
