import type { Context as DataDogContext } from '@datadog/browser-core'

import type { User } from 'shared/utils/logging/types/User'

import datadog from './integrations/datadog'
import fullStory from './integrations/full-story'
import googleAnalytics from './integrations/google-analytics'
import rollbar from './integrations/rollbar'
import type { TrackOptionsInterface } from './integrations/google-analytics'

const TAP = false

const STATE = {
  RENDERS: false,
  WEBSOCKETS: false,
  UPDATE_CHECKS: false,
  SHOW_EVENT_TRIGGERS: false,
  SHOW_EVENT_REGISTERS: false,
  SHOW_EVENT_CLEARS: false,
  FRONTEND_SEARCH: false,
  SHOW_DISPATCHER_GET: false,
}

/**
 * Define `TrackOptions`.
 */

interface TrackOptions extends TrackOptionsInterface {
  group?: 'Reporting' | 'Onboarding' | 'Dispatch'
}

const track = (name: string, options: TrackOptions = {}) =>
  Promise.all([googleAnalytics.track(name, options), datadog.logInfo(name, options)])

const identify = (user: User) =>
  Promise.all([googleAnalytics.identify(user), fullStory.identify(user), rollbar.identify(user)])

const visitPage = (path: string, title: string) => {
  if (window.ENV?.DISABLE_LOGGING_INTEGRATIONS) {
    return
  }

  googleAnalytics.visitPage(path, title)
}

const logDebug = (name: string, data?: any) => {
  if (window.ENV?.DISABLE_LOGGING_INTEGRATIONS) {
    return
  }

  if (TAP) {
    console.log('debug', name, data)
  }
}

const logInfo = (name: string, data: DataDogContext = {}) => {
  if (window.ENV?.DISABLE_LOGGING_INTEGRATIONS) {
    return
  }

  datadog.logInfo(name, { online: navigator.onLine, ...data })
}

const logError = (...args: Array<Rollbar.LogArgument>) => {
  if (window.ENV?.DISABLE_LOGGING_INTEGRATIONS) {
    return
  }

  rollbar.logError(...args, { online: navigator.onLine })
}

const logWarning = (...args: [string, DataDogContext] | Array<Rollbar.LogArgument>) => {
  if (window.ENV?.DISABLE_LOGGING_INTEGRATIONS) {
    return
  }

  let message: string | undefined

  if (typeof args[0] === 'string') {
    ;[message] = args
  }

  if (args[0] instanceof Error) {
    message = args[0].message
  }
  if (message) {
    datadog.logWarning(message, { online: navigator.onLine, ...(args[1] as DataDogContext) })
  }
}

export default {
  identify,
  visitPage,
  logDebug,
  logInfo,
  logError,
  logWarning,
  track,
  STATE,
}
