/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/unbound-method */
import Api from 'web-client/utils/api'
import { partial, pickBy } from 'lodash'
import Consts from 'consts'
import dataDog from 'shared/utils/logging/integrations/datadog'
import relayService from 'shared/utils/relay'
// eslint-disable-next-line no-restricted-imports
import { fetchQuery, graphql } from 'relay-hooks'
import { actionCableProSelected } from 'graphql/createCableHandler'
import type { FetchResponse } from 'web-client/utils/swoopFetch'
import type { asyncRequestStoreQuery } from './__generated__/asyncRequestStoreQuery.graphql'

const FallbackQuery = graphql`
  query asyncRequestStoreQuery($id: Int!) {
    asyncResponse(id: $id) {
      payload
    }
  }
`

type AsyncRequestData = {
  id?: number | null
  swoop_timeout?: boolean
  error?: boolean
  data?: Record<string, any>
  async_request_id?: number | null
  async_request?: {
    id: number
  }
}

type Response = {
  success: (data: Record<string, any> | undefined | null) => void
  error: (data: Record<string, any>) => void
}

type WaitForResponse =
  | { job_customer_payment?: unknown; errorMsg?: string; job_customer_rate?: object }
  | null
  | undefined

class AsyncRequestStore {
  lookup: Record<number, Response>

  dataLookup: Record<number, { data?: AsyncRequestData; graphql?: boolean; time?: Date }>

  requestTime: Record<number, number>

  handledMessages: Record<number, number>

  requestNames: Record<number, string>

  reportingTimes: Record<number, any>

  constructor() {
    // Responsible for looking up ws callback
    this.lookup = {}
    // Responsible for looking up data on http response (if WS came back first)
    this.dataLookup = {}

    // Time of request
    this.requestTime = {}
    this.handledMessages = {}
    this.requestNames = {}
    this.reportingTimes = {}
  }

  genericRequest = (
    apiCall: (response: {
      success: (data: any) => void
      error: (data: any) => void
    }) => Promise<void | FetchResponse> | void,
    response: Response,
    request_name: string,
    waitTimeout: number = Consts.WS_ASYNC_REQUEST_TIMEOUT
  ) => {
    let async_request_id: number | null | undefined = null

    const timeout = setTimeout(async () => {
      if (async_request_id) {
        const resp = await fetchQuery<asyncRequestStoreQuery>(
          relayService.environment,
          FallbackQuery,
          { id: async_request_id }
        ).toPromise()
        if (resp?.asyncResponse?.payload) {
          this.addRemoteItem({
            ...(resp.asyncResponse.payload as object),
            graphql: true,
            fallbackResponse: true,
          })
          return
        }
      }
      dataDog.logInfo('Async Request Timeout', {
        async_request_id,
        request_name,
      })
      response.error({
        swoop_timeout: true,
        async_request_id,
      })
    }, waitTimeout)

    // Reponsible for clearing timeout on passing through success to calling component
    const wsSuccess = (wsData: AsyncRequestData | null | undefined) => {
      clearTimeout(timeout)
      response.success(wsData)
    }

    // Reponsible for clearing timeout on passing through error to calling component
    const error = (data: AsyncRequestData) => {
      clearTimeout(timeout)
      response.error(data)
    }

    const initialRequestTime = window.performance.now()
    // Makes the api request to get the async request id and processes it
    void apiCall({
      success: (data: { async_request?: { id: number } | null | undefined }) => {
        const wsResponse = { success: wsSuccess, error }
        // Check if WS came back first
        const previousWsResponseLookup = this.dataLookup[data?.async_request?.id ?? -1]

        if (previousWsResponseLookup) {
          // If WS data did come back first, then handle it immediately
          this.handleWSResponse(
            previousWsResponseLookup.data,
            wsResponse,
            previousWsResponseLookup.graphql === true
          )
        } else {
          // If no WS response yet, set up a callback on a lookup for when it does
          this.lookup[data?.async_request?.id ?? -1] = wsResponse
        }
        async_request_id = data?.async_request?.id
        this.requestTime[async_request_id ?? -1] = initialRequestTime
        this.requestNames[async_request_id ?? -1] = request_name
        dataDog.logInfo('Async Request HTTP Response', {
          async_request_id,
          request_name,
        })
      },
      error,
    })
  }

  searchPoliciesNew = (
    jobId: number,
    search_terms: string,
    endpoint: string,
    response: Response
  ) => {
    const apiCall = partial(Api.searchPoliciesNew, endpoint, jobId, search_terms)
    this.genericRequest(apiCall, response, 'searchPoliciesNew', Consts.WS_ASYNC_REQUEST_TIMEOUT_PLS)
  }

  logoutOfPhone = (
    extension: string,
    user: { id: number; username: string; email: string },
    response: Response
  ) => {
    const apiCall = partial(Api.logoutOfPhone, extension, user)
    return this.genericRequest(apiCall, response, 'logoutOfPhone')
  }

  /**
   * @param asyncRequestId
   * @param {number} wsResponseTimeout
   * @returns {Promise<{ job_customer_payment?: any, errorMsg?: string, job_customer_rate?: any }>}
   */
  waitFor = (
    asyncRequestId: number | null | undefined,
    wsResponseTimeout: number = Consts.WS_ASYNC_REQUEST_TIMEOUT,
    requestName: string = 'default'
  ): Promise<WaitForResponse> => {
    return new Promise((resolve, reject) => {
      let timeout: NodeJS.Timeout
      const handleSuccess = (wsData: WaitForResponse) => {
        clearTimeout(timeout)
        resolve(wsData)
      }

      const wsResponse = { success: handleSuccess, error: reject }
      this.requestTime[asyncRequestId ?? -1] = window.performance.now()
      this.requestNames[asyncRequestId ?? -1] = requestName

      timeout = setTimeout(async () => {
        if (asyncRequestId) {
          dataDog.logInfo('Async Request Timeout', {
            asyncRequestId,
            requestName,
          })
          const resp = await fetchQuery<asyncRequestStoreQuery>(
            relayService.environment,
            FallbackQuery,
            { id: asyncRequestId }
          ).toPromise()
          if (resp?.asyncResponse?.payload) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.addRemoteItem({
              ...(resp.asyncResponse.payload as object),
              graphql: true,
              fallbackResponse: true,
            })
            return
          }
        }

        reject(new Error(Consts.WS_TIMEOUT_ERROR_MESSAGE))
      }, wsResponseTimeout)

      const previousWsResponseLookup = this.dataLookup[asyncRequestId ?? -1]
      if (previousWsResponseLookup) {
        this.handleWSResponse(
          previousWsResponseLookup.data,
          wsResponse,
          previousWsResponseLookup.graphql === true
        )
      } else {
        this.lookup[asyncRequestId ?? -1] = wsResponse
      }
    })
  }

  logReporting = (asyncRequestId: number | null | undefined, responder: string) => {
    if (!this.reportingTimes[asyncRequestId ?? -1]) {
      this.reportingTimes[asyncRequestId ?? -1] = {}
    }
    this.reportingTimes[asyncRequestId ?? -1][responder] = {
      time: window.performance.now(),
    }
    setTimeout(() => {
      if (
        this.reportingTimes[asyncRequestId ?? -1] &&
        (this.requestNames[asyncRequestId ?? -1] || responder === 'graphql')
      ) {
        const graphqlTime = this.reportingTimes[asyncRequestId ?? -1].graphql?.time
        const oldWSTime = this.reportingTimes[asyncRequestId ?? -1].old_websocket?.time
        let winner = null
        if (graphqlTime && oldWSTime) {
          winner = graphqlTime < oldWSTime ? 'graphql' : 'old_websocket'
        } else if (graphqlTime) {
          winner = 'graphql'
        } else if (oldWSTime) {
          winner = 'old_websocket'
        }
        dataDog.logInfo('Async Request Method Comparison', {
          graphqlFinished: !!graphqlTime,
          oldWebsocketFinished: !!oldWSTime,
          graphqlFasterBy: graphqlTime && oldWSTime ? graphqlTime - oldWSTime : null,
          winner,
          graphqlTime: graphqlTime ? graphqlTime - this.requestTime[asyncRequestId ?? -1] : null,
          oldWebsocketTime: oldWSTime ? oldWSTime - this.requestTime[asyncRequestId ?? -1] : null,
          request_name: this.requestNames[asyncRequestId ?? -1],
        })
        delete this.reportingTimes[asyncRequestId ?? -1]
        delete this.requestTime[asyncRequestId ?? -1]
      }
    }, Consts.WS_ASYNC_REQUEST_TIMEOUT * 2)
  }

  handleWSResponse = (
    asyncRequest: AsyncRequestData | undefined,
    response: Response,
    isGraphqlResponse: boolean
  ) => {
    this.logReporting(asyncRequest?.id, isGraphqlResponse ? 'graphql' : 'old_websocket')

    if (asyncRequest?.error) {
      response.error(asyncRequest)
    } else {
      response.success(asyncRequest?.data)
    }
    // Clean up any data that we had stored
    delete this.lookup[asyncRequest?.id ?? -1]
    delete this.dataLookup[asyncRequest?.id ?? -1]
  }

  // Since users get WS data for all messages we should clean up to make sure we're not
  // keeping around to many unnecessary ones
  cleanupDataLookup = () => {
    // Remove any data that's been around for longer than Consts.WS_ASYNC_REQUEST_TIMEOUT
    this.dataLookup = pickBy(this.dataLookup, (value) => {
      const dateNumber = (value.time?.getTime() ?? 0) + Consts.WS_ASYNC_REQUEST_TIMEOUT
      const limitDate = new Date(dateNumber)
      return new Date() < limitDate
    })
  }

  addRemoteItem = (msg: {
    graphql: boolean
    target?: AsyncRequestData
    fallbackResponse: boolean
  }) => {
    const asyncRequest = msg.target
    const response = this.lookup[asyncRequest?.id ?? -1]

    const responder = msg.graphql === true ? 'graphql' : 'old_websocket'
    const cable = actionCableProSelected ? 'actionCablePro' : 'actionCable'
    const actionCableProCheck = msg.graphql ? { cable } : {}

    if (response != null) {
      // If the http request has set up a lookup id for a response, callback
      this.handleWSResponse(asyncRequest, response, msg.graphql === true)
    } else {
      this.logReporting(asyncRequest?.id, responder)
      this.cleanupDataLookup()
      // Potentially WS came back before http response
      // Store for later
      this.dataLookup[asyncRequest?.id ?? -1] = {
        data: asyncRequest,
        time: new Date(),
        graphql: msg.graphql,
      }
    }
    if (this.requestNames[asyncRequest?.id ?? -1] || responder === 'graphql') {
      if (this.handledMessages[asyncRequest?.id ?? -1]) {
        // second message to come in after http request
        dataDog.logInfo('Websocket Response Time', {
          delta: window.performance.now() - this.handledMessages[asyncRequest?.id ?? -1],
          responder,
          fallback_response: !!msg.fallbackResponse,
          async_request_id: asyncRequest?.id,
          request_name: this.requestNames[asyncRequest?.id ?? -1],
          beat_http: false,
          ...actionCableProCheck,
        })
        delete this.handledMessages[asyncRequest?.id ?? -1]
        delete this.requestNames[asyncRequest?.id ?? -1]
      } else if (this.requestTime[asyncRequest?.id ?? -1]) {
        // First message to come in after http request
        dataDog.logInfo('Websocket Response Time', {
          delta: window.performance.now() - this.requestTime[asyncRequest?.id ?? -1],
          responder,
          fallback_response: !!msg.fallbackResponse,
          async_request_id: asyncRequest?.id,
          request_name: this.requestNames[asyncRequest?.id ?? -1],
          beat_http: false,
          ...actionCableProCheck,
        })
        this.handledMessages[asyncRequest?.id ?? -1] = this.requestTime[asyncRequest?.id ?? -1]
      } else if (msg.graphql) {
        // if the graphql request beat the http response
        dataDog.logInfo('Websocket Response Time', {
          responder,
          fallback_response: !!msg.fallbackResponse,
          async_request_id: asyncRequest?.id,
          request_name: this.requestNames[asyncRequest?.id ?? -1],
          beat_http: true,
          ...actionCableProCheck,
        })
      }
    }
  }
}

export default new AsyncRequestStore()
