import type { Credentials as LegacyCredentials } from 'web-client/utils/authenticate'
import legacyAuthenticateWithOauth from 'web-client/utils/authenticate'
import logging from 'shared/utils/logging'

const oauthService = {
  legacy: {
    revokeToken(): Promise<void> {
      throw new Error('Not implemented')
    },
  },

  injectLegacy(methodName: 'revokeToken', method: () => Promise<void>) {
    oauthService.legacy[methodName] = method
  },

  async authenticate(credentials: LegacyCredentials) {
    await legacyAuthenticateWithOauth(credentials)

    logging.logInfo('Logged In with Credentials', {
      feature: 'oauth',
    })
  },

  revokeToken(): Promise<void> {
    return oauthService.legacy.revokeToken()
  },
}

export default oauthService
