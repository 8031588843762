/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Consts from 'consts'
import { getBaseUrl } from 'web-client/utils/baseUrl'
import { extend, isEmpty, map, mapValues } from 'lodash'
import qs from 'qs'
import Dispatcher from 'shared/utils/dispatcher' // TODO: add bearer in header
import { refreshTokenIfNeeded } from 'web-client/utils/auth-refresh'
import dataDog from 'shared/utils/logging/integrations/datadog'
import logging from 'shared/utils/logging'
import type { FetchResponse } from 'web-client/utils/swoopFetch'
import swoopFetch from 'web-client/utils/swoopFetch'
import SwoopHeaders from 'web-client/utils/swoopHeaders'
import { sendNotification } from 'utilsLibrary/notificationUtils'
import type { Context } from '@datadog/browser-core'

export const GET = 'GET'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const UPDATE = 'PATCH'

export const NEW_BEARER_TOKEN = 'newBearerToken'

export type Response = {
  success?: (data?: any) => void | Promise<void>
  error?: (jqXHR?: (FetchResponse & { responseJSON?: any }) | null, error?: any) => void
}

let _token: string | null = null
let _expiresAt: number | null = null
const Api = {
  PARTNER: 'partner',
  FLEET: 'fleet',
  GET,
  POST,
  DELETE,
  UPDATE,

  getBearerToken() {
    return _token
  },

  setBearerToken(token: string) {
    _token = token
    SwoopHeaders.setBearerToken(token)
    Dispatcher.send(NEW_BEARER_TOKEN, {
      token,
    })
  },

  getExpiresAt() {
    return _expiresAt
  },

  setExpiresAt(expiresAt: number) {
    _expiresAt = expiresAt
  },

  clearBearerToken() {
    SwoopHeaders.clearBearerToken()
  },

  revokeToken(andThen: () => void) {
    return swoopFetch('/oauth/revoke', {
      headers: SwoopHeaders.headers,
      method: Api.POST,
      body: JSON.stringify({
        token: _token,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw Error(await response.text())
        }
      })
      .finally(andThen)
  },

  _showAuthError() {
    return sendNotification(Consts.MESSAGE_AUTH_ERR, Consts.NOTIFICATION_TYPES.ERROR, {
      timeout: Consts.N_TIME,
      id: 'auth_error',
    })
  },

  async request(
    method: string,
    url: string,
    data: object | null | undefined,
    callbacks: {
      success?: (data?: any) => void
      error?: (response: any, jQueryTextStatus: '', responseText: string | undefined) => void
    } = {},
    options: {
      ajaxOptions?: { dataType?: 'json' | 'text' }
      ignore401?: boolean
      ignoreFrontendFailure?: boolean
      overrideUrl?: boolean
    } = {}
  ): Promise<void | FetchResponse> {
    const { error: errorCallback, success: successCallback } = callbacks ?? {}

    const localOptions = options
    let apiUrl = `${Consts.API_PATH}${url}`

    if (localOptions.overrideUrl) {
      apiUrl = url
    }

    const token = await refreshTokenIfNeeded()
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    Api.setBearerToken(token as string)

    let response: FetchResponse | undefined

    try {
      response = await swoopFetch(apiUrl, {
        headers: SwoopHeaders.headers,
        method,
        ...(data &&
          Object.keys(data).length && {
            body: JSON.stringify(data),
          }),
      })
    } catch (err) {
      const responseText = 'Something went wrong with the network'
      logging.logInfo('[api.ts] network error', err as Context)
      return errorCallback?.(
        {
          status: response?.status,
          responseText,
        },
        '',
        responseText
      )
    }

    if (response.ok) {
      if (successCallback) {
        const responseText = await response.text()

        successCallback(
          options?.ajaxOptions?.dataType === 'text' || !responseText
            ? responseText
            : JSON.parse(responseText)
        )
      }
    } else if (response.status === 401 && !localOptions.ignore401) {
      try {
        const tkn = await refreshTokenIfNeeded(true)
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        Api.setBearerToken(tkn as string)
      } catch (e) {
        const responseText = await response.text()

        return errorCallback?.(
          {
            ...(options?.ajaxOptions?.dataType !== 'text' && {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              responseJSON: JSON.parse(responseText as string),
            }),
            status: response.status,
            responseText,
          },
          '',
          responseText
        )
      }
      return Api.request(
        method,
        url,
        data,
        { error: errorCallback, success: successCallback },
        { ...localOptions, ignore401: true }
      )
    } else if (response.status === 0 && !localOptions.ignoreFrontendFailure) {
      return Api.request(
        method,
        url,
        data,
        { error: errorCallback, success: successCallback },
        { ...localOptions, ignoreFrontendFailure: true }
      )
    } else {
      const responseText = await response.text()

      const errorResponse = { status: response.status, responseText }

      if (options?.ajaxOptions?.dataType !== 'text') {
        try {
          // @ts-ignore
          errorResponse.responseJSON = JSON.parse(responseText)
        } catch (e) {
          // we could not parse JSON, so it is probably not JSON
        }
      }

      return errorCallback?.(errorResponse, '', responseText)
    }

    return response
  },

  login(username: string | null, pass: string, response: Response) {
    if (username == null) {
      return
    }

    const data = {
      grant_type: 'password',
      password: pass,
      ...(username.indexOf('@') > 0
        ? {
            email: username.toLowerCase().trim(),
          }
        : {
            username: username.toLowerCase().trim(),
          }),
    }

    return this.process_login(data, response)
  },

  process_login(
    data: { grant_type: string; password: string; email?: string; username?: string },
    { error: errorCallback, success: successCallback }: Response = {}
  ) {
    return (
      swoopFetch('/oauth/token', {
        headers: SwoopHeaders.headers,
        method: Api.POST,
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          if (response.ok) {
            try {
              return response.json()
            } catch (err) {
              const e = err as { code: string; error: string; message: string; stack: string }
              const message = 'Error parsing JSON'
              dataDog.logError(message, {
                error: {
                  code: e.code || e.error,
                  message: e.message,
                  stack: e.stack,
                },
              })
              const thrownError = `${message} ${e.message}`
              throw Error(thrownError)
            }
          } else {
            throw Error(await response.text())
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .then((data) => {
          void successCallback?.(data)
          return data
        })
        .catch((error) => {
          errorCallback?.(null, error)
          throw error
        })
    )
  },

  jobChoosePartner(data: { job: { id: number } }, endpoint: string, response: Response) {
    const url = `${Consts.API_PATH}${endpoint}/jobs/${data.job.id}/choose_partner`
    return Api.request(Api.UPDATE, url, data, response, { overrideUrl: true })
  },

  getExcludedServices(response: Response) {
    return Api.request(Api.GET, 'root/excluded_services', null, response)
  },

  getServices(response: Response) {
    return Api.request(Api.GET, 'services?active=true', null, response)
  },

  getTime({ error: errorCallback, success: successCallback }: Response = {}) {
    return swoopFetch(`/time?${new Date().getTime()}`, {
      headers: SwoopHeaders.headers,
    }).then(
      async (response) => {
        if (response.ok) {
          const result = await response.json()
          void successCallback?.(result)
        } else {
          errorCallback?.(response, Error(await response.text()))
        }
      },
      (error) => {
        errorCallback?.(null, error)
      }
    )
  },

  refreshTokenRequest(token: string, response: Response) {
    const data = {
      grant_type: 'refresh_token',
      refresh_token: token,
    }
    return Api.request(Api.POST, `${getBaseUrl()}${Consts.ROOT_PATH}/oauth/token`, data, response, {
      overrideUrl: true,
      ignore401: true,
    })
  },

  objectsRequest(
    url: string,
    requestType: string,
    id: string | null,
    response: Response | undefined,
    data?: object | undefined | null
  ) {
    return Api.request(requestType, `${url}${id != null ? `/${id}` : ''}`, data, response ?? {})
  },

  userMeRequest(response: Response) {
    return Api.objectsRequest('users/me', Api.GET, null, response, null)
  },

  undeleteUser(data: object, response: Response) {
    return Api.request(Api.UPDATE, 'root/users/restore', data, response)
  },

  undeleteJob(data: { id: string }, response: Response) {
    return Api.request(Api.UPDATE, `root/jobs/${data.id}/restore`, data, response)
  },

  undeleteCompany(id: string, response: Response) {
    return Api.request(Api.UPDATE, `root/companies/${id}/restore`, null, response)
  },

  deleteStripeAccount(data: { name: string }, response: Response) {
    return Api.request(
      Api.DELETE,
      `root/custom_accounts?rescue_company_id=${data.name}`,
      null,
      response
    )
  },

  getCompanyView(id: number, response: Response) {
    return Api.objectsRequest(
      `root/companies/init?acts_as_company_id=${id}`,
      Api.GET,
      null,
      response
    )
  },

  getDispatchableSites(response: Response) {
    return Api.objectsRequest('partner/sites/dispatchable', Api.GET, null, response)
  },

  getMotorClubAccounts(response: Response) {
    return Api.objectsRequest('partner/accounts/motorclub', Api.GET, null, response)
  },

  getAccountsWithoutRate(response: Response) {
    return Api.objectsRequest('partner/accounts/without_rates', Api.GET, null, response)
  },

  documentUploadRequest(userType: string, id: string, props: object | null, response: Response) {
    return Api.request(
      Api.GET,
      `${userType}/jobs/${id}/attached_documents/presign`,
      props,
      response
    )
  },

  documentCreate(userType: string, id: string, props: object, response: Response) {
    return Api.request(Api.POST, `${userType}/jobs/${id}/attached_documents`, props, response)
  },

  documentUpdate(userType: string, id: string, obj: { id: string }, response: Response) {
    return Api.request(
      Api.UPDATE,
      `${userType}/jobs/${id}/attached_documents/${obj.id}`,
      obj,
      response
    )
  },

  getLogoCompany(userType: string) {
    return userType === 'fleet' ? 'rescue_company' : 'company'
  },

  logoUploadRequest(userType: string, id: string | null, props: object | null, response: Response) {
    return Api.request(
      Api.GET,
      `${userType}/${this.getLogoCompany(userType)}/logo/presign`,
      props,
      response
    )
  },

  logoCreate(userType: string, id: string | null, props: object, response: Response) {
    return Api.request(
      Api.POST,
      `${userType}/${this.getLogoCompany(userType)}/logo`,
      props,
      response
    )
  },

  updateCommission(commission: object, response: Response) {
    return Api.request(Api.UPDATE, 'partner/company/commission', commission, response)
  },

  updateRolePermissions(permission: object, response: Response) {
    return Api.request(Api.UPDATE, 'partner/company/role_permissions', permission, response)
  },

  updateAddonCommissionsExclusions(commissionExclusions: object, response: Response) {
    return Api.request(
      Api.UPDATE,
      'partner/commissions/addons/exclusions',
      commissionExclusions,
      response
    )
  },

  updateServiceCommissionsExclusions(commissionExclusions: object, response: Response) {
    return Api.request(
      Api.UPDATE,
      'partner/commissions/services/exclusions',
      commissionExclusions,
      response
    )
  },

  logoRemove(userType: string, response: Response) {
    return Api.request(
      Api.DELETE,
      `${userType}/${this.getLogoCompany(userType)}/logo`,
      null,
      response
    )
  },

  updateJobDocument(type: string, job_id: string, props: { id: string }, response: Response) {
    return Api.request(
      Api.UPDATE,
      `${type}/jobs/${job_id}/attached_documents/${props.id}`,
      props,
      response
    )
  },

  getVehiclesWithDriver(response: Response) {
    return Api.objectsRequest('partner/vehicles/with_driver', Api.GET, null, response)
  },

  requestCallbackRequest(type: string, id: string, data: object, response: Response) {
    return Api.request(Api.POST, `${type}/jobs/${id}/request_callback`, data, response)
  },

  jobCopy(type: string, id: string, response: Response) {
    return Api.request(Api.POST, `${type}/jobs/${id}/copy`, null, response)
  },

  partnerCommand(data: object, response: Response) {
    return Api.request(Api.POST, 'partner/commands', data, response)
  },

  sendJobDetailsEmail(endpoint: string, data: { id: string }, response: Response) {
    return Api.request(Api.POST, `${endpoint}/jobs/${data.id}/send_details_email`, data, response)
  },

  rejectJob(rtype: string, type: string, id: string, response: Response, data: object) {
    return Api.request(Api.POST, `${type}/jobs/${id}/reject`, data, response)
  },

  releaseJob(rtype: string, type: string, id: string, response: Response, data: object) {
    return Api.request(Api.UPDATE, `${type}/jobs/${id}/release`, data, response)
  },

  jobsStatusRequest(
    requestType: string,
    type: string,
    id: string,
    response: Response,
    job: object
  ) {
    return Api.objectsRequest(`${type}/jobs/${id}/status`, requestType, null, response, job)
  },

  jobsRequest(requestType: string, type: string, id: string, response: Response, job: object) {
    return Api.objectsRequest(`${type}/jobs/`, requestType, id, response, job)
  },

  invoiceCharge(id: number, charge_obj: object, response: Response) {
    return Api.objectsRequest(`partner/invoices/${id}/charge`, Api.POST, null, response, charge_obj)
  },

  rscRequestMoreTime(job_id: number, response: Response) {
    return Api.objectsRequest(
      `partner/jobs/${job_id}/request_more_time`,
      Api.POST,
      null,
      response,
      null
    )
  },

  invoicePaidRequest(
    requestType: string,
    type: string,
    id: string,
    response: Response,
    job: object
  ) {
    return Api.objectsRequest(`${type}/invoices/${id}/paid`, requestType, null, response, job)
  },

  invoiceRequest(requestType: string, type: string, id: string, response: Response, job: object) {
    return Api.objectsRequest(`${type}/invoices`, requestType, id, response, job)
  },

  locationTypeRequest(requestType: string, id: string, response: Response, data?: object) {
    return Api.objectsRequest('location_types', requestType, id, response, data)
  },

  locationTypeSearch(data: object, response: Response) {
    return Api.objectsRequest('location_types/search', Api.POST, null, response, data)
  },

  parseSearch(
    search: string,
    entityType: 'job' | 'user' | 'company' | 'provider' | 'account' = 'job'
  ): { original?: string; term?: string } {
    const keysByType = {
      job: [
        'job',
        'po',
        'service',
        'pickup',
        'dropoff',
        'customer',
        'phone',
        'vin',
        'license',
        'make',
        'model',
        'dispatched',
        'completed',
        'stored',
        'notes',
        'partner_notes',
        'driver_notes',
        'dispatch_notes',
        'account',
        'fleet',
        'ref_number',
        'partner',
        'swoop_notes',
        'fleet_notes',
        'status',
        'original_job',
        'department',
      ],
      user: [
        'id',
        'user',
        'username',
        'company_id',
        'email',
        'first_name',
        'last_name',
        'phone',
        'company_name',
      ],
      company: [
        'id',
        'name',
        'phone',
        'support_email',
        'accounting_email',
        'dispatch_email',
        'parent_company_id',
        'type',
      ],
      provider: [
        'id',
        'name',
        'dispatch_phone',
        'dispatch_email',
        'fax',
        'address',
        'primary_contact',
        'primary_phone',
        'primary_email',
        'accounting_email',
        'special_instructions',
        'vendor_id',
        'location_id',
        'wheels',
      ],
      account: [
        'id',
        'name',
        'address',
        'primary_phone',
        'primary_email',
        'accounting_email',
        'notes',
      ],
    }
    const keys = keysByType[entityType] // Doesn't allow escaped "'s
    // Matches a single param in the form of key:nospaces or key:"includes
    // spaces but no escaped quotes"
    // eslint-disable-next-line
    const re = new RegExp(`(${keys.join('|')}):((?:"[^"]*")|[^\ ]*)`, 'i')
    let str = search
    const hash: { original: string; term?: string } = {
      original: search,
      term: '',
    }
    let results = re.exec(str)

    while (results) {
      const needle = results[1].toLowerCase() as
        | 'dispatched'
        | 'completed'
        | 'stored'
        | 'original'
        | 'term'
      str = str.replace(results[0], '')
      const val = results[2].replace(/^"/, '').replace(/"$/, '') // Make sure that if expecting a date it matches, otherwise put it in term

      if (
        !['dispatched', 'completed', 'stored'].includes(needle) ||
        // eslint-disable-next-line
        val.match(/(\d{8})(-\d{8})?$/) ||
        entityType !== 'job'
      ) {
        hash[needle as keyof typeof hash] = val
      } else {
        hash.term += results[0]
      }

      results = re.exec(str)
    }

    if (str != null) {
      hash.term += str.replace(/\s\s+/g, ' ').trim()
    }

    if (hash.term?.length === 0) {
      delete hash.term
    }

    return hash
  },

  searchPaginated(
    searchUrl: string,
    searchValue: string,
    filters: Record<string, any>,
    searchPage: number,
    paginationSize: number,
    order: string,
    type: 'job' | 'user' | 'company' | 'provider' | 'account',
    response: Response
  ) {
    let hackedFilters: Record<string, any> | null | undefined
    let querySearchTerm = ''
    let originalTerm = ''

    if (searchValue?.length > 0) {
      const querySearchParsed = this.parseSearch(searchValue, type)

      if (querySearchParsed.original) {
        originalTerm = `&${qs.stringify(
          {
            original: querySearchParsed.original,
          },
          { arrayFormat: 'brackets' }
        )}`
        delete querySearchParsed.original
      } else {
        originalTerm = ''
      }

      querySearchTerm = `&${qs.stringify(
        {
          search_terms: querySearchParsed,
        },
        { arrayFormat: 'brackets' }
      )}`
    }

    if (filters != null) {
      // This is a hack as qs.stringify (and rails) excludes empty arrays
      // https://stackoverflow.com/a/31996181
      hackedFilters = mapValues(filters, (v) => {
        if (Array.isArray(v)) {
          if (v.length === 0) {
            return null
          } else {
            return map(v, (el) => {
              if (el === null) {
                return 'null'
              } else {
                return el
              }
            })
          }
        }

        return v
      })
    }

    const queryFilters =
      hackedFilters != null ? `&${qs.stringify(hackedFilters, { arrayFormat: 'brackets' })}` : ''
    const queryOrder = order != null ? `&order=${order}` : ''
    const finalUrl = `${searchUrl}?page=${searchPage}&per_page=${paginationSize}${queryFilters}${queryOrder}${originalTerm}${querySearchTerm}`
    return Api.request(Api.GET, finalUrl, null, response)
  },

  runReport(type: string, id: string, filters: object, format: string, response: Response) {
    return Api.request(
      Api.POST,
      `${type}/reports/${id}/run.${format || 'csv'}`,
      {
        report: filters != null ? filters : {},
      },
      response
    )
  },

  generateInvoicePdf(invoice_uuid: string, response: Response) {
    return Api.request(Api.POST, `invoices/${invoice_uuid}/pdfs`, null, response)
  },

  showInvoicePdf(
    invoice_uuid: string,
    invoice_pdf_id: string,
    responseContentDisposition: string,
    response: Response
  ) {
    return Api.request(
      Api.GET,
      `invoices/${invoice_uuid}/pdfs/${invoice_pdf_id}?response_content_disposition=${responseContentDisposition}`,
      null,
      response
    )
  },

  activeJobsRequest(
    requestType: string,
    type: string,
    response: Response,
    page: number,
    load_count: number = Consts.JOBS_PER_PAGE_COUNT,
    cursor: string | null = null
  ) {
    return Api.request(
      requestType,
      `${type}/jobs/active/?page=${page}&per_page=${load_count}&cursor=${cursor}`,
      null,
      response
    )
  },

  activeDriverJobsRequest(requestType: string, type: string, response: Response, page: number) {
    return Api.request(
      requestType,
      `${type}/users/me/jobs/?page=${page}&per_page=${Consts.JOBS_PER_PAGE_COUNT}`,
      null,
      response
    )
  },

  getUser(response: Response) {
    return Api.request(Api.GET, 'users/me', null, response)
  },

  getSampleRates(
    id: number,
    userEndpoint: string,
    company_id: number,
    type: string,
    vtype: string,
    response: Response
  ) {
    let url = `${userEndpoint}/invoices/${id}/samplerate` // will be passed when UserStore.isSuperCompany(). @see invoice_fields

    if (company_id) {
      url += `?company_id=${company_id}`
    }

    return Api.request(
      Api.POST,
      url,
      {
        invoice: {
          rate_type: type,
          invoice_vehicle_category_id: vtype,
        },
      },
      response
    )
  },

  getPartnerServices(partner_id: number, response: Response) {
    return Api.request(Api.GET, `fleet/services/partner/${partner_id}`, null, response)
  },

  getPartnerVehicleCategories(partner_id: number, response: Response) {
    return Api.request(Api.GET, `fleet/vehicle_categories/partner/${partner_id}`, null, response)
  },

  createDemoJob(response: Response) {
    return Api.request(Api.POST, 'partner/jobs/demo', null, response)
  },

  addJobIssue(endpoint: string, job_id: number, props: object, response: Response) {
    return Api.request(Api.POST, `${endpoint}/jobs/${job_id}/explanations/`, props, response)
  },

  updateJobIssue(type: string, job_id: number, props: { id: number }, response: Response) {
    return Api.request(
      Api.UPDATE,
      `${type}/jobs/${job_id}/explanations/${props.id}`,
      props,
      response
    )
  },

  sendPush(username: string, message: string, response: Response) {
    return Api.request(Api.POST, 'root/push_notifications', { username, message }, response)
  },

  getApiCredentials(response: Response) {
    return Api.request(Api.GET, 'oauth_applications', {}, response)
  },

  generateApiCredentials(response: Response) {
    return Api.request(Api.POST, 'oauth_applications', {}, response)
  },

  deleteApiCredentials(id: number, response: Response) {
    return Api.request(Api.DELETE, `oauth_applications/${id}`, {}, response)
  },

  createObjects(props: object, response: Response) {
    return Api.request(Api.POST, 'root/fake_objects', props, response)
  },

  searchPolicies(request_type: string, number: number, jobId: number, response: Response) {
    return Api.request(
      Api.POST,
      `${request_type}/policy`,
      {
        number,
        job_id: jobId,
      },
      response
    )
  },

  requestDropLocationOptions(request_type: string, payload: object, response: Response) {
    return Api.request(Api.POST, `${request_type}/dropoff_sites`, payload, response)
  },

  searchPoliciesNew(request_type: string, jobId: number, search_terms: string, response: Response) {
    return Api.request(
      Api.POST,
      `${request_type}/policy/search`,
      {
        search_terms,
        job_id: jobId,
      },
      response
    )
  },

  invoicesPaginated(
    type: string,
    page: number,
    states: Array<string>,
    response: Response,
    extras: { job_search?: string; order?: string; invoice_state: Array<string> } | null = null
  ) {
    let localExtras = extras
    if (localExtras?.job_search != null) {
      // STRIP OUT SEARCH HERE AND PULL OUT KEYS
      let order: string | undefined, original: string | undefined
      const searchMap = this.parseSearch(localExtras.job_search)

      delete localExtras.job_search

      if (localExtras.order != null) {
        order = localExtras.order
        delete localExtras.order
      }

      localExtras.invoice_state = states

      if (searchMap.original != null) {
        original = searchMap.original
        delete searchMap.original
      }

      if (isEmpty(localExtras)) {
        localExtras = null
      }

      void Api.request(
        Api.GET,
        `${type}/invoices_search?page=${page}&per_page=25${
          original != null
            ? `&${qs.stringify(
                {
                  original,
                },
                { arrayFormat: 'brackets' }
              )}`
            : ''
        }${order != null ? `&order=${order}` : ''}${
          localExtras != null
            ? `&${qs.stringify(
                {
                  filters: localExtras,
                },
                { arrayFormat: 'brackets' }
              )}`
            : ''
        }&${qs.stringify({
          search_terms: searchMap,
        })}`,
        null,
        response
      )
      return
    }

    if (isEmpty(localExtras)) {
      localExtras = null
    }

    return Api.request(
      Api.GET,
      `${type}/invoices?page=${page}&per_page=25&states=${states.join(',')}${
        localExtras != null ? `&${qs.stringify(localExtras, { arrayFormat: 'brackets' })}` : ''
      }`,
      null,
      response
    )
  },

  standardServices(endpoint: string, response: Response) {
    return Api.request(Api.GET, `${endpoint}/services/standard`, null, response)
  },

  standardAddons(endpoint: string, response: Response) {
    return Api.request(Api.GET, `${endpoint}/services/standard?addons=true`, null, response)
  },

  standardSymptoms(endpoint: string, response: Response) {
    return Api.request(Api.GET, 'symptoms/standard', null, response)
  },

  changeServices(services_object: object, response: Response) {
    return Api.request(
      Api.POST,
      'services/change',
      {
        service: {
          change: services_object,
        },
      },
      response
    )
  },

  changeAddons(addons_object: object, response: Response) {
    return Api.request(
      Api.POST,
      'services/change?addons=true',
      {
        service: {
          change: addons_object,
        },
      },
      response
    )
  },

  changeSymptoms(symptoms_object: object, response: Response) {
    return Api.request(
      Api.POST,
      'symptoms/change',
      {
        symptom: {
          change: symptoms_object,
        },
      },
      response
    )
  },

  batchServiceByName(names: Array<string>, addon: number, response: Response) {
    return Api.request(
      Api.GET,
      `services/batch_by_name?addons=${addon.toString()}&service_names=${names.toString()}`,
      null,
      response
    )
  },

  getServicesDashboardFilters(response: Response) {
    return Api.request(Api.GET, 'services/dashboard_filters', null, response)
  },

  loadServiceByName(name: string, addon: number, response: Response) {
    return Api.batchServiceByName([name], addon, response)
  },

  invoice(uuid: string, response: Response) {
    return Api.request(Api.GET, `/invoices/uuid/${uuid}?inline_jobs=true`, {}, response)
  },

  drivers(response: Response) {
    return Api.request(Api.GET, '/partner/companies/drivers', {}, response)
  },

  vehicleMakes(response: Response) {
    return Api.request(Api.GET, '/vehicles/makes', {}, response)
  },

  setPhoneStatus(
    {
      userId,
      username,
      extension,
      status,
    }: { userId: number; username: string; extension: string; status: string },
    response: Response
  ) {
    return Api.request(
      Api.POST,
      'root/cti/agent_status',
      {
        username,
        extension: String(extension),
        user_id: String(userId),
        status,
      },
      response
    )
  },

  logoutOfPhone(
    extension: string,
    user: { id: number; username: string; email: string },
    response: Response
  ) {
    void Api.setPhoneStatus(
      {
        userId: user.id,
        username: user.username || user.email,
        extension,
        status: 'offline',
      },
      response
    )
  },

  // eslint-disable-next-line default-param-last
  autocomplete_company(type: any = null, term: string, count: number, response: Response) {
    const args = {
      autocomplete: {
        type,
        term,
        count,
      },
    }
    return Api.request(Api.POST, '/root/autocomplete/company', args, response)
  },

  autocomplete_rescue_providers(
    term: string,
    // eslint-disable-next-line default-param-last
    limit = 10,
    userEndpoint: string,
    response: Response
  ) {
    return this.autocomplete(term, 'rescue_providers', limit, userEndpoint, response)
  },

  autocomplete_accounts(
    term: string,
    limit: number,
    userEndpoint: string,
    response: Response,
    filters: object = {}
  ) {
    return this.autocomplete(term, 'accounts', limit, userEndpoint, response, filters)
  },

  autocomplete(
    term: string,
    type: string,
    // eslint-disable-next-line default-param-last
    limit: number = 10,
    userEndpoint: string,
    response: Response,
    filters: object = {}
  ) {
    if (!term || !type || !userEndpoint) {
      return
    }

    const default_params = {
      term,
      limit,
    }
    const args = {
      autocomplete: extend({}, default_params, filters),
    }
    return Api.request(Api.POST, `/${userEndpoint}/autocomplete/${type}`, args, response)
  },

  signupAgeroPartner(email: string, client_id: number, redirect_uri: string) {
    return fetch('/auth/email_verifications', {
      method: Api.POST,
      body: JSON.stringify({ email, client_id, redirect_uri }),
      headers: SwoopHeaders.headers,
    }).then(async (response) => {
      if (!response.ok) {
        throw Error(await response.text())
      }
    })
  },

  createPasswordRequestAgeroPartner(email: string, uid: string) {
    return fetch(`${getBaseUrl()}/auth/password_requests`, {
      body: JSON.stringify({ user: { email }, application: { uid } }),
      method: Api.POST,
      headers: SwoopHeaders.headers,
    }).then(async (response) => {
      if (!response.ok) {
        throw Error(await response.text())
      }
    })
  },

  changePasswordAgeroPartner(uuid: string, password: string) {
    return fetch(`${getBaseUrl()}/auth/password_requests/${uuid}`, {
      body: JSON.stringify({ user: { password } }),
      method: Api.UPDATE,
      headers: SwoopHeaders.headers,
    }).then(async (response) => {
      if (!response.ok) {
        throw Error(await response.text())
      }
    })
  },
}

export default Api
