import Consts from 'base_consts'
import useCurrentJob from 'consumer-mobile-web/hooks/useCurrentJob'
import useCurrentUser from 'web-client/hooks/useCurrentUser'

type Result = {
  dynamic_environment: string
  email?: string
  company_id?: number
  company_name?: string
  company_type?: string
}

export default function useSplitAttributes() {
  const { currentUser } = useCurrentUser()
  const { currentJob } = useCurrentJob()
  const result: Result = {
    dynamic_environment: Consts.SWOOP_ENV,
  }

  if (currentUser?.email) {
    result.email = currentUser.email
  }

  if (currentUser?.company) {
    result.company_id = currentUser.company.swcid
    result.company_type = currentUser.company.type
    result.company_name = String(currentUser.company.name)
  } else if (currentJob?.company) {
    result.company_id = currentJob.company.swcid
    result.company_type = currentJob.company.type
    result.company_name = String(currentJob.company.name)
  }

  return result
}
