/* eslint-disable no-console */
import logging from 'shared/utils/logging'
// @ts-ignore
import MicroEvent from 'microevent-github'

import { isEmpty, keys } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
interface EventRegistry {
  bind: (eventType: string, callback: () => void) => void
  unbind: (eventType: string, callback: () => void) => void
  trigger: (eventType: string, event?: any) => void
}

const RERENDER_INTERVAL = 1500

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
class EventRegistry {
  _watchedEvents: any

  _watcherToEvents: { [key: string | number]: Array<(number | string | null)[]> }

  _pendingTriggerIds: Set<number | string>

  _triggerTimeout: any

  constructor() {
    this._watchedEvents = {}
    this._watcherToEvents = {}
    this._pendingTriggerIds = new Set()
    this._triggerTimeout = null

    this._createNestedMap = this._createNestedMap.bind(this)
    this._getKeys = this._getKeys.bind(this)
    this.addPendingTriggerId = this.addPendingTriggerId.bind(this)
    this.clearListeners = this.clearListeners.bind(this)
    this.getWatchedProperties = this.getWatchedProperties.bind(this)
    this.register = this.register.bind(this)
    this.triggerListListeners = this.triggerListListeners.bind(this)
    this.triggerObjListeners = this.triggerObjListeners.bind(this)
    this.triggerPropListeners = this.triggerPropListeners.bind(this)
    this.triggerRerender = this.triggerRerender.bind(this)
    this.sendPendingTriggers = this.sendPendingTriggers.bind(this)

    setInterval(this.sendPendingTriggers, RERENDER_INTERVAL)
  }

  register(
    watcherId: number,
    modelId: number,
    listId: number | null = null,
    objId: number | null = null,
    property: string | null = null
  ) {
    if (logging.STATE.SHOW_EVENT_REGISTERS) {
      console.log(watcherId, modelId, listId, objId, property)
    }

    if (watcherId != null) {
      const eventKey = [modelId, listId, objId, property, watcherId]

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this._createNestedMap(...[...(eventKey || [])])

      if (this._watcherToEvents[watcherId] == null) {
        this._watcherToEvents[watcherId] = []
      }

      return this._watcherToEvents[watcherId].push(eventKey)
    }
  }

  triggerRerender(watcherId: number | string) {
    return this.trigger(this.getTrigger(watcherId))
  }

  /* This is primarily used for tests, as the interval is created before timers are
   mocked it can't be controlled by mockTimers
   */

  forceSendAllPendingTriggers = () => {
    if (this._pendingTriggerIds.size > 0) {
      this._pendingTriggerIds.forEach((id) => {
        this.triggerRerender(id)
      })
    }
  }

  /*
    This will send any pending triggers that are queued up to cause rerenders
     It only triggers one each tick to allow for other processes
     on the main thread to take place (like UI interactions)
     */
  sendPendingTriggers = () => {
    if (this._pendingTriggerIds.size > 0) {
      const itr = this._pendingTriggerIds.values()
      const { value }: IteratorResult<string | number, string | number> = itr.next()
      this._pendingTriggerIds.delete(value)
      this.triggerRerender(value)
      if (this._pendingTriggerIds.size > 0) {
        setTimeout(this.sendPendingTriggers, 0)
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getTrigger = (watcherId: number | string) => `RERENDER_${watcherId}`

  triggerListeners = (
    modelId: number,
    listId: number | null = null,
    objId: number | null = null,
    property: string | null = null
  ) => {
    // Queue up rerender calls but wait for them to come in to uniquify
    const watcherIds = this._getKeys(modelId, listId, objId, property)

    if (logging.STATE.SHOW_EVENT_TRIGGERS) {
      console.log(modelId, listId, objId, property, watcherIds)
    }

    if (watcherIds.length > 0) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      watcherIds.forEach(this._pendingTriggerIds.add, this._pendingTriggerIds)
    }
  }

  addPendingTriggerId(id: number) {
    this._pendingTriggerIds.add(id)
  }

  triggerPropListeners(modelId: number, listId: number, objId: number, property: string) {
    this.triggerListeners(modelId, listId, objId, property)
  }

  triggerObjListeners(modelId: number, listId: number, objId: number) {
    this.triggerListeners(modelId, listId, objId, null)
  }

  triggerListListeners(modelId: number, listId: number) {
    this.triggerListeners(modelId, listId, null, null)
  }

  getWatchedProperties(modelId: number, listId: number, objId: number) {
    const idPropertyWatchers = this._getKeys(modelId, listId, objId)

    const generalPropertyWatchers = this._getKeys(modelId, listId, null)

    const watchedProps = idPropertyWatchers.concat(generalPropertyWatchers)
    return watchedProps
  }

  clearListeners(watcherId: number | string | null) {
    if (logging.STATE.SHOW_EVENT_CLEARS) {
      console.log(watcherId)
    }

    if (watcherId != null) {
      if (this._watcherToEvents[watcherId]) {
        this._watcherToEvents[watcherId].forEach((stringKeys) => {
          let listId: number | string | undefined | null,
            modelId: number | string | undefined | null,
            objId: number | string | undefined | null,
            prop: string | number | undefined | null
            // TODO: this if statement shouldn't be needed, figure outwhy [prop]
            // was sometimes returning null
            // eslint-disable-next-line no-param-reassign
          ;[modelId, listId, objId, prop, watcherId] = [...stringKeys]

          // it is possible that some of the keys are null and this doesn't result on runtime error
          // so ignoring those errors
          // @ts-ignore
          if (this._watchedEvents[modelId]?.[listId]?.[objId]?.[prop]?.[watcherId]) {
            // @ts-ignore
            delete this._watchedEvents[modelId][listId][objId][prop][watcherId]

            // @ts-ignore
            if (isEmpty(this._watchedEvents[modelId][listId][objId][prop])) {
              // @ts-ignore
              delete this._watchedEvents[modelId][listId][objId][prop]
            }

            // @ts-ignore
            if (isEmpty(this._watchedEvents[modelId][listId][objId])) {
              // @ts-ignore
              delete this._watchedEvents[modelId][listId][objId]
            }
          }
        })
      }

      return delete this._watcherToEvents[watcherId]
    }
  }

  _getKeys(...args: Array<number | string | null>) {
    let base = this._watchedEvents

    for (const arg of args) {
      // @ts-ignore
      if (!base[arg]) {
        return []
      }

      // @ts-ignore
      base = base[arg]
    }

    return keys(base)
  }

  _createNestedMap(...args: Array<number | string | null>) {
    let base = this._watchedEvents

    // eslint-disable-next-line no-restricted-syntax
    for (const arg of args) {
      // @ts-ignore
      if (!base[arg]) {
        // @ts-ignore
        base[arg] = {}
      }

      // @ts-ignore
      base = base[arg]
    }

    return base
  }
}

MicroEvent.mixin(EventRegistry)

const eventRegistry = new EventRegistry()

export default eventRegistry
