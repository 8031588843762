/* eslint react/no-multi-comp: 0 */

import React, { useEffect, useRef, useState } from 'react'
import { Polygon, useGoogleMap } from '@react-google-maps/api'
import { forEach, filter } from 'lodash'

// Converts numeric degrees to radians
function toRad(value) {
  return (value * Math.PI) / 180
}

// Calculates the distance between two points, as the crow flies
// Looks like haversine formula https://en.wikipedia.org/wiki/Haversine_formula
function calcCrow(lat1, lon1, lat2, lon2) {
  const R = 6371 // Earth's radius (km)
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const _lat1 = toRad(lat1)
  const _lat2 = toRad(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(_lat1) * Math.cos(_lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d
}

const fitBounds = (map, locations, opts = {}) => {
  /* eslint-disable-next-line no-param-reassign */
  opts.zoom = opts.zoom || 12
  const validLocations = filter(locations, (location) => location?.lat && location?.lng)
  if (validLocations.length === 1) {
    const [{ lat, lng, viewport }] = validLocations
    map.setCenter(new window.google.maps.LatLng(lat, lng))
    map.setZoom(opts.zoom)
    if (viewport) {
      map.fitBounds(viewport)
    }
  } else if (validLocations.length > 1) {
    const bounds = new window.google.maps.LatLngBounds()
    forEach(validLocations, (location) => {
      const { lat, lng } = location
      bounds.extend(new window.google.maps.LatLng(lat, lng))
    })
    map.fitBounds(bounds, opts)
    return true
  }
  return false
}

const MapControls = ({ children, className = undefined, position = 'LEFT_CENTER' }) => {
  const map = useGoogleMap()
  const mapControls = map.controls[window.google.maps.ControlPosition[position]]
  const controls = useRef()

  useEffect(() => {
    mapControls.push(controls.current)

    return () => {
      mapControls.pop()
    }
  }, [controls, mapControls])

  return (
    <div className={className} ref={controls}>
      {children}
    </div>
  )
}

const PolygonWithListeners = ({ onRemove, onSet, onInsert, ...props }) => {
  const [polygon, setPolygon] = useState(null)
  const path = (polygon && polygon.getPath()) || null

  useEffect(() => {
    if (path) {
      if (onInsert) {
        window.google.maps.event.addListener(path, 'insert_at', () => onInsert(polygon))
      }
      if (onRemove) {
        window.google.maps.event.addListener(path, 'remove_at', () => onRemove(polygon))
      }
      if (onSet) {
        window.google.maps.event.addListener(path, 'set_at', () => onSet(polygon))
      }
      return () => {
        window.google.maps.event.clearInstanceListeners(path)
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [path])

  return <Polygon onLoad={(thisPolygon) => setPolygon(thisPolygon)} {...props} />
}

export { calcCrow, fitBounds, MapControls, PolygonWithListeners }
