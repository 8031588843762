export const CONFIRMED_LOCATION = 'cmw-confirm-location'
export const LANDING_SEEN = 'cmw-landing-seen'
export const MWI_REQUEST_ERROR_COUNT = 'mwi-request-error-count'
export const PROFILE_LOOKUP_DATA = 'mwi-profile-lookup-data'
export const INTAKE_WELCOME = 'mwi-intake-welcome'
export const MANUAL_ENTRY_SKIP_VIN = 'mwi-manual-entry-skip-vin'
export const PROFILE_LOOKUP = 'mwi-profile-lookup'
export const SELECTED_VEHICLE = 'mwi-selected-vehicle'
export const SELECTED_DRIVER = 'mwi-selected-driver'
export const VIN_LOOKUP = 'mwi-vin-lookup'
export const CALLBACK_REQUESTED = 'mwi-callback-requested'
export const SEARCH_LIMIT_REACHED = 'mwi-search-limit-reached'
