import { Environment, Network, RecordSource, Store, Observable, ROOT_TYPE } from 'relay-runtime'

import createCableHandler from 'graphql/createCableHandler'
import { refreshTokenIfNeeded } from 'web-client/utils/auth-refresh'
import datadog from 'shared/utils/logging/integrations/datadog'
import swoopFetch from 'web-client/utils/swoopFetch'
import { getBaseUrl } from 'web-client/utils/baseUrl'
import logging from 'shared/utils/logging'

import type {
  GraphQLResponseWithData,
  RequestParameters as Operation,
  Variables,
  CacheConfig,
  ReaderSelection,
  NormalizationSelection,
  MissingFieldHandler,
} from 'relay-runtime'
import { getMobileAuthorization } from 'consumer-mobile-web/utils/jobutils'
import { forEach } from 'lodash'

const logGraphQLError = (error: Error, operation: Operation) => {
  datadog.logError(`GraphqlError: ${error.message} [${operation.name}]`, {
    error,
    operation,
  })
}

if (!window.swoop) {
  window.swoop = {}
}
if (!window.swoop.pendingFetchCount) {
  window.swoop.pendingFetchCount = 0
}
class GraphqlFetchError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'GraphqlFetchError'
  }
}

export const enum RelayJSONParseErrorType {
  name = 'SyntaxError',
  relayErrorMessage = 'The string did not match the expected pattern.',
  newErrorMessage = 'RelayJSONParseError: The string did not match the expected pattern.',
}

class RelayJSONParseError extends Error {
  constructor(name: string) {
    super()
    this.message = `Request: ${name} - ${RelayJSONParseErrorType.newErrorMessage}`
  }
}

type FetchProps = {
  operation: Operation
  variables: Variables
  cacheConfig: CacheConfig
}
type RelayService = {
  environment: Environment
  versionCallbacks: Record<number, (version: string) => void>
  versionCallbackId: number
  onVersionReceived: (callback: (version: string) => void) => void
  enableBackgroundFetch: boolean
  setUseBackgroundFetch: (boolean: boolean) => void
  fetch: (props: FetchProps) => Promise<any>
  fetchWithPotentialSessionRefresh: (
    operation: Operation,
    variables: Variables,
    cacheConfig: CacheConfig
  ) => Promise<any>
  create: () => Environment
}

const isRelayJSONParseError = (error: Error) =>
  error.name === RelayJSONParseErrorType.name &&
  error.message === RelayJSONParseErrorType.relayErrorMessage

const isFetchError = (error: Error) =>
  error.name === 'TypeError' && error.message === 'Failed to fetch'

const getErrorMessage = (error: Error, name?: string) => {
  if (isFetchError(error)) {
    return new GraphqlFetchError(`Failed to fetch ${name}`)
  }
  if (isRelayJSONParseError(error)) {
    return new RelayJSONParseError(name || 'unknown')
  }
  return error
}

const createError = (operation: Operation, errors: unknown) => {
  let message = `GraphQL operation error: ${operation.name}`

  if (Array.isArray(errors)) {
    const errorWithMessage = errors.find((error) => 'message' in error)

    if (errorWithMessage) {
      message += `: ${errorWithMessage.message}`
    }
  }

  return new Error(message, { cause: errors })
}

export const missingFieldHandlers: ReadonlyArray<MissingFieldHandler> = [
  {
    handle(field, record, argValues): string | undefined {
      if (
        record?.getType() === ROOT_TYPE &&
        field.name === 'invoice' &&
        field.concreteType === 'Invoice' &&
        argValues.id
      ) {
        return argValues.id
      }

      if (record?.getType() === ROOT_TYPE && field.name === 'node' && argValues.id) {
        const isInvoiceNode = field.selections.some(
          (selection: NormalizationSelection | ReaderSelection) => {
            return (
              selection.kind === 'InlineFragment' &&
              'type' in selection &&
              selection.type === 'Invoice'
            )
          }
        )

        if (isInvoiceNode) {
          return argValues.id
        }
      }

      return undefined
    },
    kind: 'linked',
  },
]

const relayService: RelayService = {
  // This will always be set in creation of singleton
  environment: undefined as unknown as Environment,
  enableBackgroundFetch: true,
  versionCallbacks: {},
  versionCallbackId: 1,
  setUseBackgroundFetch(enableBackgroundFetch: boolean) {
    relayService.enableBackgroundFetch = enableBackgroundFetch
  },

  onVersionReceived(callback) {
    this.versionCallbackId += 1
    const id = this.versionCallbackId
    this.versionCallbacks[id] = callback
    return () => {
      delete this.versionCallbacks[id]
    }
  },

  async fetch({ operation, variables, cacheConfig }: FetchProps) {
    const metadata = cacheConfig?.metadata
    let token = null
    // TODO: Make the relayService more composable
    const mobileAuth = !metadata?.skipMobileAuthToken && getMobileAuthorization()
    if (!mobileAuth && !metadata?.skipTokenRefresh) {
      token = await refreshTokenIfNeeded()
    }

    const headers = {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(mobileAuth ? { Authorization: mobileAuth } : {}),
      ...(metadata?.headers as Record<string, string>),
    }
    const fetchUrl =
      !this.enableBackgroundFetch && getBaseUrl() ? `${getBaseUrl()}/graphql` : '/graphql'
    const fetchCall = this.enableBackgroundFetch ? swoopFetch : window.fetch
    window.swoop.pendingFetchCount += 1
    return fetchCall(fetchUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query: operation.text,
        variables,
        operationName: operation.name,
      }),
    })
      .then((response) => {
        window.swoop.pendingFetchCount -= 1
        return response
      })
      .catch((error) => {
        window.swoop.pendingFetchCount -= 1
        throw error
      })
  },

  async fetchWithPotentialSessionRefresh(
    operation: Operation,
    variables: Variables,
    cacheConfig: CacheConfig
  ) {
    let response = await relayService.fetch({ operation, variables, cacheConfig })

    if (response.status === 401 && !cacheConfig?.metadata?.skipTokenRefresh) {
      const token = await refreshTokenIfNeeded(true)
      if (!token) {
        return
      }

      response = await relayService.fetch({ operation, variables, cacheConfig })
    }
    try {
      if (response?.headers) {
        const swoopVersion = response.headers.get('x-swoop-version')
        if (swoopVersion) {
          forEach(this.versionCallbacks, (callback) => {
            callback(swoopVersion)
          })
        }
      }
    } catch (e) {
      logging.logError(e as Error)
    }
    return response.json() as Promise<GraphQLResponseWithData>
  },

  create() {
    const subscriptionHandler = createCableHandler()

    const fetchQuery = (operation: Operation, variables: Variables, cacheConfig: CacheConfig) =>
      Observable.create((sink) => {
        relayService
          .fetchWithPotentialSessionRefresh(operation, variables, cacheConfig)
          .then((data) => {
            if (!data) {
              logGraphQLError(Error('missing data property on response'), operation)
              sink.error(new Error(`Invalid response from server: ${operation.name}`))
              return
            }

            if (operation?.operationKind === 'query' && data.errors) {
              const error = createError(operation, data.errors)
              sink.error(error)
            } else {
              sink.next(data)
            }

            if (data.errors) {
              const [firstError] = data.errors
              const message = `Partial success: ${firstError?.message} [${operation.name}]`
              datadog.logError(message, { data, variables })
            }
          })
          .catch((error: Error) => {
            logGraphQLError(error, operation)
            if ('message' in error) {
              try {
                // eslint-disable-next-line no-param-reassign
                error.message = `${error.message} [${operation.name}]`
              } catch (e) {
                // ignore
              }
            }

            sink.error(error)
          })
          .finally(() => sink.complete())
      })

    const environment = new Environment({
      missingFieldHandlers,
      network: Network.create(fetchQuery, subscriptionHandler),
      store: new Store(new RecordSource()),
    })

    relayService.environment = environment

    return environment
  },
}

relayService.create()

export default relayService

export { FetchTimeoutError } from 'web-client/utils/swoopFetch'
