import datadog from 'shared/utils/logging/integrations/datadog'
import type { OauthTokenResponse } from 'web-client/utils/auth-refresh'
import swoopFetch from 'web-client/utils/swoopFetch'
import sessionService from 'web-client/utils/session'

export type Credentials = (
  | {
      email: string
    }
  | {
      username: string
    }
) & {
  password: string
}

async function authenticate(credentials: Credentials) {
  try {
    const response = await swoopFetch('/oauth/token', {
      headers: { Authorization: '', 'Content-Type': 'application/json' },
      method: 'post',
      body: JSON.stringify({
        ...credentials,
        grant_type: 'password',
      }),
    })

    // eslint-disable-next-line object-curly-newline
    const { access_token, error, error_description, refresh_token, expires_in } =
      (await response.json()) as OauthTokenResponse

    if (!response.ok) {
      const username = 'email' in credentials ? credentials.email : credentials.username
      const errorMessage = `Error authenticating: ${
        error ? `${error}: ${error_description}` : ''
      } for user ${username}`
      throw Error(errorMessage)
    }

    sessionService.setBearerToken(access_token)
    sessionService.setRefreshToken(refresh_token)
    sessionService.setExpiresAtFromExpiresIn(expires_in)
  } catch (error) {
    /* istanbul ignore if */
    if (!(error instanceof Error)) {
      throw Error
    }

    datadog.logError(error.message, error)
    throw error
  }
}

export default authenticate
